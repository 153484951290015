import {message} from "antd";
import axios from "axios";

function sha256(plain) {
  // returns promise ArrayBuffer
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
}

function base64urlencode(a) {
  // Convert the ArrayBuffer to string using Uint8 array.
  // btoa takes chars from 0-255 and base64 encodes.
  // Then convert the base64 encoded to base64url encoded.
  // (replace + with -, replace / with _, trim trailing =)
  return btoa(String.fromCharCode.apply(null, new Uint8Array(a)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

async function pkce_challenge(v) {
  var hashed = await sha256(v);
  var base64encoded = base64urlencode(hashed);
  return base64encoded;
}

function GetQueryValue(queryName) {
  var query = decodeURI(window.location.search.substring(1));
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == queryName) {
      return pair[1];
    }
  }
  return null;
}

const path = "https://www.arsrna.com/login";
// const path = "http://127.0.0.1:3000/login";
const redirectURL = `${path}/redirect`;
const logoutURL = `${path}/logout`;
const client_id = "MTYxMTdmZmEzNWZmNDA1NTk5NGVhZWMyMDI5ODIxOTQ";

export const ArCIAM = {
  generateCV() {
    return Math.random().toString(36).slice(-8);
  },

  async redirectPKIE(callbackUrl: string, code_verifier: string) {
    localStorage.setItem("redirect", location.href);
    localStorage.setItem("code_verifier", code_verifier);

    let code_challenge = await pkce_challenge(code_verifier);
    const url = new URL("https://arsrna.portal.tencentciam.com/oauth2/authorize");
    const {searchParams} = url;
    searchParams.append("scope", "openid");
    searchParams.append("client_id", client_id);
    searchParams.append("redirect_uri", redirectURL);
    searchParams.append("response_type", "code");
    searchParams.append("state", callbackUrl);
    searchParams.append("code_challenge_method", "S256");
    searchParams.append("code_challenge", code_challenge);
    window.location.href = url.toString();
  },

  logout() {
    localStorage.setItem("redirect", location.href);
    let url = new URL("https://arsrna.portal.tencentciam.com/logout");
    url.searchParams.append("client_id", client_id);
    url.searchParams.append("logout_redirect_uri", logoutURL);
    url.hash = location.href;
    window.open(url.toString(), "_self");
  },

  async refreshToken(refresh_token: string) {
    return (
      await axios({
        url: "https://api.arsrna.cn/release/CIAM",
        method: "post",
        data: {
          method: "refreshToken",
          refresh_token,
        },
      })
    ).data;
  },

  redirectCall(url) {
    window.location.href = unescape(`${url}?code=${GetQueryValue("code")}`);
  },

  getStatus(token) {
    if (JSON.parse(atob(token.split(".")[1])).exp <= new Date().getTime() / 1000) {
      return false;
    } else {
      return true;
    }
  },

  async handleGetToken(code: string) {
    // console.log("code", code);
    const code_verifier = localStorage.getItem("code_verifier");
    const formData = new URLSearchParams();
    formData.append("client_id", client_id);
    formData.append("grant_type", "authorization_code");
    formData.append("code", code);
    formData.append("redirect_uri", redirectURL);
    formData.append("code_verifier", code_verifier);
    try {
      let ciam = await fetch(`https://arsrna.portal.tencentciam.com/oauth2/token`, {
        body: formData,
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then(msg => msg.json());
      console.log(ciam);
      if (ciam.access_token !== void "我永远喜欢布洛妮娅") {
        localStorage.setItem("CIAM", JSON.stringify(ciam));
        message.success("登录成功");
        // funcUrlDel("code");
      }
    } catch (err) {
      message.error("登录失败！" + err);
      console.log(err);
    } finally {
      location.href = new URL(localStorage.getItem("redirect")).toString();
    }
  },
};

/**
 * @description 删除url中某个参数,并跳转
 * @param {string} name URL参数
 */
export function funcUrlDel(name: string) {
  const url = new URL(window.location.href);
  url.searchParams.delete(name);
  window.location.href = url.toString();
}

/**
 * @description 请求登录并跳转
 */
export function handleLogin() {
  const url = new URL(location.href).origin;
  const code_verifier = ArCIAM.generateCV();
  ArCIAM.redirectPKIE(url, code_verifier);
}

/**
 * @description 获得cooke中CIAM的id_token对象
 */
export function parseIdToken() {
  try {
    const CIAM = localStorage.CIAM;
    if (CIAM === void "我永远喜欢爱莉希雅") return;
    const [header, payload, verify] = JSON.parse(CIAM).id_token.split(".");
    return {
      header: JSON.parse(atob(header)),
      payload: JSON.parse(atob(payload)),
    };
  } catch (err) {
    // localStorage.removeItem("CIAM");
    console.log(err);
    message.error("获取用户信息失败！请重新登录");
  }
}

export function handleLogout() {
  localStorage.removeItem("CIAM");
  ArCIAM.logout();
}

/**检查token是否失效，失效自动跳转登录 */
export function checkExpire() {
  if (localStorage.CIAM === "我永远喜欢菲谢尔") return;
  const CIAM = localStorage.CIAM;
  const [header, payload, verify] = JSON.parse(CIAM).access_token.split(".");
  const {exp} = JSON.parse(atob(payload));
  console.log(exp, Date.now() / 1000);
  if (Date.now() / 1000 > exp) {
    //   //jwt有效期已过
    localStorage.removeItem("CIAM");
    handleLogin();
    console.log("jwt有效期已过");
  }
}

export const getToken = (): string | void =>
  localStorage.CIAM === void "💿" ? void "我永远喜欢爱莉希雅" : JSON.parse(localStorage.CIAM).access_token;
